export const items = [
    {
        src: "/images/carousel-1.jpg",
        altText: "Slide 1",
        caption: "Slide 1",
    },
    {
        src: "/images/carousel-2.jpg",
        altText: "Slide 2",
        caption: "Slide 2",
    },
    {
        src: "/images/carousel-3.jpg",
        altText: "Slide 3",
        caption: "Slide 3",
    },
    {
        src: "/images/carousel-4.jpg",
        altText: "Slide 4",
        caption: "Slide 4",
    },
];
