export const imgText = [
    {
        id: 0,
        title: "West Charleston and Jones",
        src: "/images/location1.jpg",
        desc:
            "The location is in front of CSN's West Charelston campus mainly to provided delicious drinks and an area for students to relax after class.",
        phone: "702-555-1234",
    },
    {
        id: 1,
        title: "Maryland and Tropicana",
        src: "/images/location2.jpg",
        desc:
            "The location is in front of UNLV to provided delicious drinks and an area for students to relax after class.",
        phone: "702-555-1234",
    },
];
